/**
 * Common store module
 *
 * Data in this module should meet the following criteria:
 *
 * - Master Data which is *not* account-specific (no account ID field)
 * - Session/user data - includes current user and account, roles, etc
 * - General UI state
 */

/* eslint-disable no-shadow */
import { make } from 'vuex-pathify';

import _ from 'underscore';

import { isBlank } from 'adready-api/helpers/common';
import { formatDateForAPI } from '@/util/apiDateFormat';
import {
  DEMO_ADVERTISERS,
  ADVANCED_PERFORMANCE_SOLUTION_ID,
  CONVERGED_TV_SOLUTION_ID,
  RANGE_LAST_30_DAYS,
  COMPARE_RANGE_PREV_DAY,
  DEFAULT_CONVERSION_WINDOW,
  KEY_DEMO_ADVERTISER_ID,
  MNI_MOTTO_SOLUTION_ID,
} from '@/constant';
import { getCampaignDateRange, isDemoInstance } from '~/util/utility-functions';

const state = {
  // Show filters boolean
  showFilters: false,

  // is Mni Account boolean
  isMniAccount: false,

  // is Via Account boolean
  isViaAccount: false,

  // List of all users
  allUsers: [],

  // List of all organizations
  allOrganizations: [],

  // List of accounts/roles the user has, as read from their JWT
  userAccountRoles: [],

  // Map<Role Name, 1> of roles for the currently active account
  currentAccountRoles: {},

  // List of accounts user has access to, loaded from DB
  userAccounts: [],

  // List of all accounts
  accounts: [],

  // Logged-in User ID, read from JWT
  currentUserId: null,

  // Logged-in User
  currentUser: null,

  // List of user global roles.
  currentUserRoles: [],

  // Currently active account ID
  currentAccountId: null,
  // For Demo active advertiser name
  advName: null,

  // For Demo active advertiser name
  demoSelectedAdvertiserName: null,

  // Account wise data map store respective data as mentioned in name.
  accountMap: {},
  accountUsersMap: {},
  accountRolesMap: {},

  // Flag used to avoid race condition on watches when switching accounts.
  //
  // Since we reset the state on switch, multiple watches can fire
  // simultaneously, causing multiple data loads. We set this true before
  // switching and then reset it from a single location after the switch.
  //
  // Default to true when app starts. This is to avoid a double-load of plan
  // data on startup and will get reset to false by the orderCriteria watch in
  // PlanDashboard while the currentAccountId watch loads the data.
  switchingAccounts: true,

  // Current account object
  account: null,

  // Current selected advertiser object
  advertiser: null,

  // pixel id
  universalPixelId: null,

  // advertiser solutions
  advertiserSolutions: [],

  // dashboard loading until we get advertiser.
  dashboardLoading: true,

  leftNavLoading: true,

  ui: {
    modal: 0,
  },
  requestSentSolutions: {},
  filterCacheId: null,
};

async function fetchSolutions(ctx, advertiserId, appConsoleDataLayer) {
  const solutions = await appConsoleDataLayer.getSolutionsByAdvertiser(advertiserId);
  ctx.commit('SET_UPDATE_ADVERTISER_SOLUTIONS', solutions);
  return solutions;
}
// this function is setting  convertTV Date to back to recent days
function setConvergedTVDateFilter(ctx) {
  const { rootState } = ctx;
  const { dates } = rootState.dashboard;
  const dateRange = {
    startDate: new Date(new Date().setDate(new Date().getDate() - 31)),
    endDate: new Date(new Date().setDate(new Date().getDate() - 2)),
    compareWith: false,
    compareStartDate: new Date(new Date().setDate(new Date().getDate() - 31)),
    compareEndDate: new Date(new Date().setDate(new Date().getDate() - 3)),
    dateRangeOption: RANGE_LAST_30_DAYS,
    dateCompareOption: COMPARE_RANGE_PREV_DAY,
  };

  ctx.commit('dashboard/SET_DATES', { ...dates, ...dateRange }, { root: true });
}

async function fetchCampaigns(ctx, advertiser, account, solution, appConsoleDataLayer) {
  const advertiserId = advertiser ? advertiser.id : 0;
  const advertiserName = advertiser ? advertiser.name : '';
  const accountName = account ? account.name : '';
  const solutionId = solution ? solution.id : 0;
  const hasXandrAdvertiserId = advertiser?.xandrAdvertiserId !== null;
  const campaigns = await appConsoleDataLayer.fetchCampaignOptions(
    advertiserId,
    advertiserName,
    accountName,
    solutionId,
    hasXandrAdvertiserId
  );
  if (!campaigns.length) {
    if (!isDemoInstance()) {
      // dont reset dates if demo instance
      ctx.commit('dashboard/SET_RESET_DATES', {}, { root: true });
    }
    return [];
  }
  const advertiserKeys = Object.keys(DEMO_ADVERTISERS).map((key) => parseInt(key, 10));
  const demoAdvertiserId = parseInt(localStorage.getItem(KEY_DEMO_ADVERTISER_ID), 10);
  const demoAdvertiserMapping = advertiserKeys.includes(demoAdvertiserId)
    ? DEMO_ADVERTISERS[demoAdvertiserId]
    : DEMO_ADVERTISERS[0];
  // Mark recent campaigns as default checked
  for (let i = 0; i < campaigns.length; i++) {
    if (isDemoInstance()) {
      if (demoAdvertiserMapping.selectedCampaign.includes(campaigns[i].value)) {
        campaigns[i].checked = true;
      }
    } else {
      campaigns[i].checked = campaigns[i].isRecent;
    }
  }
  if (solution?.id === CONVERGED_TV_SOLUTION_ID || solution?.id === MNI_MOTTO_SOLUTION_ID) {
    setConvergedTVDateFilter(ctx);
  } else {
    // const { rootState } = ctx;
    // const { dates } = rootState.dashboard;
    // let dateRange;
    // if (isDemoInstance()) {
    //   dateRange = { ...dates };
    // } else {
    //   console.log('index');
    //   dateRange = getCampaignDateRange(campaigns, true);
    //   ctx.commit('dashboard/SET_DATES', { ...dates, ...dateRange }, { root: true });
    // }
  }
  return campaigns;
}

async function fetchAdGroups(ctx, advertiser, solution, campaignsData, appConsoleDataLayer) {
  if (solution?.id === MNI_MOTTO_SOLUTION_ID) return [];
  const advertiserId = advertiser ? advertiser.id : 0;
  const solutionId = solution ? solution.id : 0;
  const adGroupData = await appConsoleDataLayer.fetchAdGroupOptions(
    advertiserId,
    solutionId,
    campaignsData
  );
  if (adGroupData?.length > 0) {
    for (let i = 0; i < adGroupData?.length; i++) {
      adGroupData[i].checked = true;
      adGroupData[i].value = adGroupData[i].name;
    }
  }
  return adGroupData;
}

async function fetchMediaTypes(advertiserId, solutionId, payload, appConsoleDataLayer) {
  const data = await appConsoleDataLayer.fetchMediaTypeOptions(advertiserId, solutionId, payload);
  const newData = [];
  if (data.length !== 0) {
    for (let i = 0; i < data.length; i++) {
      newData.push({ value: data[i], checked: true });
    }
  }
  return newData;
}
async function fetchAudiences(advertiserId, solutionId, payload, appConsoleDataLayer) {
  const data = await appConsoleDataLayer.fetchAudienceOptions(advertiserId, solutionId, payload);
  const filterOptions = [];
  if (data.length > 0) {
    data?.forEach((d) =>
      filterOptions.push({
        key: d.value,
        value: d.key,
        checked: false,
      })
    );
  }
  return filterOptions;
}

async function fetchCreatives(advertiserId, solutionId, payload, appConsoleDataLayer) {
  const data = await appConsoleDataLayer.fetchCreativeOptions(advertiserId, solutionId, payload);
  const filterOptions = [];
  if (data.length) {
    data?.forEach((d) => filterOptions.push({ ...d, checked: false }));
  }
  return filterOptions;
}

async function fetchKeywords(advertiserId, solutionId, payload, appConsoleDataLayer) {
  if (solutionId === CONVERGED_TV_SOLUTION_ID) {
    return [];
  }
  const data = await appConsoleDataLayer.fetchKeywordOptions(advertiserId, solutionId, payload);
  const filterOptions = [];
  if (data.length) {
    data?.forEach((d) => filterOptions.push({ key: d, value: d, checked: false }));
  }
  return filterOptions;
}

async function fetchConversionPixels(advertiserId, solutionId, payload, appConsoleDataLayer) {
  if (solutionId === MNI_MOTTO_SOLUTION_ID) return [];
  if (solutionId === CONVERGED_TV_SOLUTION_ID) {
    return [];
  }
  const data = await appConsoleDataLayer.fetchConversionPixelOptions(
    advertiserId,
    solutionId,
    payload
  );
  const filterOptions = [];
  if (data.length) {
    data?.forEach((d) => filterOptions.push({ ...d, checked: true }));
  }
  return filterOptions;
}

async function fetchSolutionRelatedFilterData(
  ctx,
  advertiser,
  account,
  solution,
  appConsoleDataLayer
) {
  const campaignsData = await fetchCampaigns(
    ctx,
    advertiser,
    account,
    solution,
    appConsoleDataLayer
  );
  const campaignIds =
    campaignsData
      .filter((e) => e.checked)
      ?.map((e) => e.key)
      ?.join(',') || '';
  const adGroupData = await fetchAdGroups(
    ctx,
    advertiser,
    solution,
    campaignsData,
    appConsoleDataLayer
  );
  const solutionId = solution ? solution.id : 0;

  const { rootState } = ctx;
  const { dates } = rootState.dashboard;
  let dateRange;
  if (isDemoInstance()) {
    dateRange = { ...dates };
  } else {
    dateRange = getCampaignDateRange(campaignsData, true);
  }

  if (dateRange.notAvailable) {
    return {
      campaignOptions: [],
      adGroupOptions: [],
      mediaTypeOptions: [],
      audienceOptions: [],
      creativeOptions: [],
      keywordOptions: [],
      pixelOptions: [],
      conversionWindow: DEFAULT_CONVERSION_WINDOW,
    };
  }

  const payload = {
    advertiser: advertiser ? advertiser.name : '',
    client: ctx.state.account ? ctx.state.account.name : '',
    campaignIds,
    adGroups: '',
    startDate: formatDateForAPI(dateRange?.startDate),
    endDate: formatDateForAPI(dateRange?.endDate),
  };

  const [
    mediaTypeData,
    audienceData,
    creativeData,
    keywordData,
    conversionPixelData,
  ] = await Promise.all([
    fetchMediaTypes(advertiser.id, solutionId, payload, appConsoleDataLayer),
    fetchAudiences(advertiser.id, solutionId, payload, appConsoleDataLayer),
    fetchCreatives(advertiser.id, solutionId, payload, appConsoleDataLayer),
    fetchKeywords(advertiser.id, solutionId, payload, appConsoleDataLayer),
    fetchConversionPixels(advertiser.id, solutionId, payload, appConsoleDataLayer),
  ]);

  return {
    campaignOptions: campaignsData || [],
    adGroupOptions: adGroupData?.length > 0 ? adGroupData : [],
    mediaTypeOptions: mediaTypeData?.length > 0 ? mediaTypeData : [],
    audienceOptions: audienceData?.length > 0 ? audienceData : [],
    creativeOptions: creativeData?.length > 0 ? creativeData : [],
    keywordOptions: keywordData?.length > 0 ? keywordData : [],
    pixelOptions: conversionPixelData?.length > 0 ? conversionPixelData : [],
  };
}

function setEmptyFilterData(ctx) {
  ctx.commit(
    'dashboard/SET_UPDATE_FILTERS_DATA',
    {
      campaignOptions: [],
      adGroupOptions: [],
      mediaTypeOptions: [],
      audienceOptions: [],
      creativeOptions: [],
      publisherOptions: [],
      keywordsOptions: [],
      pixelOptions: [],
      conversionWindow: DEFAULT_CONVERSION_WINDOW,
    },
    { root: true }
  );
}

const actions = {
  switchSolution: async (
    ctx,
    { appConsoleDataLayer, advertiserId, solutionId, isChangeFilter }
  ) => {
    const advertiser = ctx.getters.GET_ADVERTISERS_LIST.find((a) => a.id === advertiserId);
    const solution =
      solutionId === 0
        ? { id: 0 }
        : ctx.getters.GET_ADVERTISER_SOLUTIONS_LIST.find((s) => s.id === solutionId);

    if (!solution) {
      ctx.commit(
        'dashboard/SET_UPDATE_FILTERS_DATA',
        {
          campaignOptions: [],
          adGroupOptions: [],
          mediaTypeOptions: [],
          audienceOptions: [],
          creativeOptions: [],
          publisherOptions: [],
          keywordOptions: [],
          pixelOptions: [],
          conversionWindow: DEFAULT_CONVERSION_WINDOW,
        },
        { root: true }
      );
      return;
    }

    // if solution is Advanced Performance then do not fetch filter data
    if ([ADVANCED_PERFORMANCE_SOLUTION_ID].includes(solution?.id)) {
      setEmptyFilterData(ctx);
      return;
    }
    if (isChangeFilter) {
      const filterData = await fetchSolutionRelatedFilterData(
        ctx,
        advertiser,
        ctx.state.account,
        solution,
        appConsoleDataLayer
      );
      ctx.commit('dashboard/SET_UPDATE_FILTERS_DATA', filterData, { root: true });
    }
  },
  switchAdvertiser: async (ctx, { appConsoleDataLayer, advertiserId }) => {
    try {
      const advertiser = ctx.getters.GET_ADVERTISERS_LIST.find((a) => a.id === advertiserId);
      ctx.commit('SET_UPDATE_ADVERTISER', advertiser);

      // set default filter data
      if (
        !ctx.state.account ||
        !advertiser ||
        isBlank(ctx.state.account.name) ||
        isBlank(advertiser.name)
      ) {
        ctx.commit(
          'dashboard/SET_UPDATE_FILTERS_DATA',
          {
            campaignOptions: [],
            adGroupOptions: [],
            mediaTypeOptions: [],
            audienceOptions: [],
            creativeOptions: [],
            publisherOptions: [],
            keywordOptions: [],
            pixelOptions: [],
            conversionWindow: DEFAULT_CONVERSION_WINDOW,
          },
          { root: true }
        );
        return;
      }

      // fetch solutions
      const solutions = await fetchSolutions(ctx, advertiserId, appConsoleDataLayer);
      const activeSolutions = solutions?.filter((s) => s.state === 'ACTIVE') || [];
      const firstSolution = activeSolutions?.length ? activeSolutions[0] : undefined;

      if (!firstSolution) {
        return;
      }

      // if solution is Advanced Performance then do not fetch filter data
      if ([ADVANCED_PERFORMANCE_SOLUTION_ID].includes(firstSolution?.id)) {
        setEmptyFilterData(ctx);
        return;
      }

      const filterData = await fetchSolutionRelatedFilterData(
        ctx,
        advertiser,
        ctx.state.account,
        firstSolution,
        appConsoleDataLayer
      );

      ctx.commit('dashboard/SET_UPDATE_FILTERS_DATA', filterData, { root: true });
    } catch (err) {
      console.error('error switching advertiser ->', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
      throw err;
    }
  },
  setShowFilters: ({ commit }, payload) => {
    commit('SET_SHOW_FILTERS', payload);
  },
  setFilterCacheId: ({ commit }, payload) => {
    commit('SET_FILTER_CACHE_ID', payload);
  },
  setConversionWindow: ({ commit }, payload) => {
    commit('SET_CONVERSION_WINDOW', payload);
  },
  setIsMniAccount: ({ commit }, payload) => {
    commit('SET_IS_MNI_ACCOUNT', payload);
  },
  setIsViaAccount: ({ commit }, payload) => {
    commit('SET_IS_VIA_ACCOUNT', payload);
  },
};

const mutations = {
  ...make.mutations(state),
  SET_SHOW_FILTERS: (state, payload) => {
    state.showFilters = payload;
  },
  SET_FILTER_CACHE_ID: (state, payload) => {
    state.filterCacheId = payload;
  },
  SET_UPDATE_UNIVERSAL_PIXEL_ID: (state, id) => {
    state.universalPixelId = id;
  },
  SET_IS_MNI_ACCOUNT: (state, payload) => {
    state.isMniAccount = payload;
  },
  SET_IS_VIA_ACCOUNT: (state, payload) => {
    state.isViaAccount = payload;
  },
  SET_UPDATE_ADVERTISER_SOLUTIONS: (state, solutions) => {
    state.advertiserSolutions = solutions || [];
  },
  SET_UPDATE_ADVERTISER: (state, advertiser) => {
    state.advertiser = advertiser;
  },
  SET_RESET_CONVERSION_WINDOW: (state, conversionWindow) => {
    state.conversionWindow = conversionWindow;
  },
};

const getters = {
  getAccount: (state) => (id) => {
    return state.accountMap[id];
  },
  getAccountRoles: (state) => (id) => {
    return state.accountRolesMap[id];
  },
  GET_ADVERTISERS_LIST(st) {
    if (!st.account) {
      return [];
    }
    return _.sortBy(st.account.advertisers, (a) => a.name.toLowerCase());
  },
  GET_ADVERTISER_SOLUTIONS_LIST(state) {
    return state.advertiserSolutions;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
